import React from 'react';

function Privacypolicy() {
  return (
    <div className="bef-login-home-sec ">
      <div className='bef-login-two-tab-sec'>
        <div className="container py-4">
          <h2 className="my-5 bef-l-text-1 text-center">Privacy Policy</h2>
        </div>
      </div>
      <div className="privacy-content container py-5">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit. 
            Nulla porttitor accumsan tincidunt. Nulla quis lorem ut libero malesuada feugiat.
          </p>

          <h4 className="my-4">1. Information Collection</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus suscipit tortor eget felis porttitor volutpat. 
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec velit neque, auctor sit amet.
          </p>

          <h4 className="my-4">2. Use of Information</h4>
          <p>
            Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit, eget tincidunt 
            nibh pulvinar a. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
          </p>

          <h4 className="my-4">3. Sharing of Information</h4>
          <p>
            Pellentesque in ipsum id orci porta dapibus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. 
            Proin eget tortor risus. Donec sollicitudin molestie malesuada.
          </p>

          <h4 className="my-4">4. Security</h4>
          <p>
            Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Cras ultricies ligula sed magna dictum porta. 
            Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
          </p>

          <h4 className="my-4">5. Changes to This Policy</h4>
          <p>
            Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Praesent sapien massa, 
            convallis a pellentesque nec, egestas non nisi.
          </p>

          <p>
            If you have any questions regarding our privacy policy, feel free to contact us.
          </p>
        </div>
    </div>


  );
}

export default Privacypolicy;
