let config = {};
let environment = "live";
if (environment == "local") {
  config = {
    frontendurl: "http://localhost:3000/",
    backendurl: "http://localhost:5000/",
  };
} else {
  config = {
    frontendurl: "https://staging.rempic.com/",
    backendurl: "https://api.rempic.com/",
  };
}
export default config;