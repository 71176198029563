import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CgFileDocument } from "react-icons/cg";
import document from '../../assets/images/home/document.png';
import envelope from "../../assets/images/home/envelope.png";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useFormik } from "formik"
import * as Yup from "yup"
import { makeApiRequest } from "../../config/AxiosService"
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

function Kycdocuments() {
    const navigate = useNavigate()
    const [nationalities, setNationalities] = useState([]);
    const [currentSection, setCurrentSection] = useState(0);
    const [value, setValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [kycStatus, setKycStatus] = useState("")

    const options = countryList().getData().map(country => ({
        value: country.label,
        label: country.label,
    }));

    const changeHandler = value => {
        setValue(value);
    };

    const handleNext = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentSection(currentSection + 1);
    };

    const getKycData = async () => {
        try {
            let params = {
                url: "user-kycData",
                method: "GET"
            }
            await makeApiRequest(params)
                .then((resp) => {
                    console.log('resp', resp)
                    const response = resp.resp.kyc_Status
                    setKycStatus(response)
                }).catch(() => {
                    if (kycStatus > "0") {
                        toast.error("Cannot get the Next page")
                    }
                })
        } catch (error) {
            toast.error("Something Wrong")
        }
    }

    useEffect(() => {
        getKycData()
        if (kycStatus == "0") {
            handleNext()
        } else if (kycStatus == "1") {
            navigate("/accountsection")
        }
    }, [kycStatus])


    useEffect(() => {
        const fetchNationalities = async () => {
            try {
                const response = await fetch('https://gist.githubusercontent.com/tiveor/5444753e9919ffe74b41/raw/47e48c7575189ef7ee228e40153a1fa57b5864b1/nationalities.json');
                const data = await response.json();

                const nationalityOptions = Object.entries(data).map(([code, name]) => ({
                    value: code,
                    label: name,
                }));

                setNationalities(nationalityOptions);
            } catch (error) {
                console.error('Error fetching nationalities:', error);
            }
        };
        fetchNationalities();
    }, []);

    const formik = useFormik({
        initialValues: {
            KYC_document: [{
                citizenship: "",
                country: "",
                US_resident: "",
                occupation: "",
                tax_ID: "",
            }],
            birth_information: [{
                birthday: "",
                birth_place: "",
                gender: "",
            }],
            kyc_Status: 0
        },
        validationSchema: Yup.object({
            KYC_document: Yup.array().of(
                Yup.object({
                    citizenship: Yup.string().required("Select Your Citizenship"),
                    country: Yup.string().required("Select Your Country"),
                    US_resident: Yup.string().required("Select whether you are a US Resident"),
                    occupation: Yup.string().matches(/^[A-Za-z\s]+$/, "Occupation must only contain alphabets").required("Enter Your Occupation"),
                    tax_ID: Yup.string().optional(),
                })
            ),
            birth_information: Yup.array().of(
                Yup.object({
                    birthday: Yup.string().required("Select Your Birthday"),
                    birth_place: Yup.string().matches(/^[A-Za-z\s]+$/, "Birth Place must only contain alphabets").required("Enter Your Birth Place"),
                    gender: Yup.string().required("Select Your Gender"),
                })
            ),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("email", localStorage.getItem("email"))
            formData.append("KYC_document[0].citizenship", values.KYC_document[0].citizenship);
            formData.append("KYC_document[0].country", values.KYC_document[0].country);
            formData.append("KYC_document[0].US_resident", values.KYC_document[0].US_resident);
            formData.append("KYC_document[0].occupation", values.KYC_document[0].occupation);
            formData.append("KYC_document[0].tax_ID", values.KYC_document[0].tax_ID || "");
            formData.append("birth_information[0].birthday", values.birth_information[0].birthday);
            formData.append("birth_information[0].birth_place", values.birth_information[0].birth_place);
            formData.append("birth_information[0].gender", values.birth_information[0].gender);
            formData.append("kyc_Status", values.kyc_Status);

            try {
                setIsLoading(true);
                const params = {
                    url: "user-kyc-verification",
                    method: "POST",
                    data: formData
                };
                const kycResp = await makeApiRequest(params);
                console.log('kycResp----', kycResp)
                if (kycResp.status == true) {
                    toast.success("The KYC Data Created Successfully")
                    setIsLoading(false);
                    setTimeout(() => {
                        if (currentSection == 0) {
                            handleNext();
                        }
                    }, 3000);
                }
            } catch (error) {
                toast.error("Internal Server Error");
                setIsLoading(false);
            }
        }
    });

    const selectedOption = options.find(option => option.value === formik.values.KYC_document[0].country) || null;

    const stateFormik = useFormik({
        initialValues: {
            legal_document: false,
            privacy_policy: false,
            bank_account: false,
            kyc_Status: 1
        },
        validationSchema: Yup.object({
            legal_document: Yup.boolean().oneOf([true], "You must accept the Terms & Conditions"),
            privacy_policy: Yup.boolean().oneOf([true], "You must accept the Privacy Policy"),
            bank_account: Yup.boolean().oneOf([true], "You must accept this Statement")
        }),
        onSubmit: async (values) => {
            const formData = new FormData()
            formData.append("legal_document", values.legal_document);
            formData.append("privacy_policy", values.privacy_policy);
            formData.append("bank_account", values.bank_account);
            formData.append("kyc_Status", values.kyc_Status);
            try {
                setLoading(true);
                const params = {
                    url: "user-kyc-verification",
                    method: "POST",
                    data: formData
                };
                const kycResp = await makeApiRequest(params);
                if (kycResp.status == true) {
                    toast.success("You Accepted all the Statement")
                    setLoading(false);
                    setTimeout(() => {
                        if (currentSection == 1) {
                            navigate("/accountsection")
                        }
                    }, 3000);
                }
            } catch (error) {
                toast.error("Internal Server Error");
                setLoading(false);
            }
        }
    })
    return (
        <div className='Kycdocuments'>
            <div className='bef-login-home-sec py-5'>
                <div className='container'>
                    <ToastContainer />
                    <div className='row justify-content-center'>
                        <div className='col-lg-7'>
                            <div className='card dash-card-1'>
                                <div className='card-body'>
                                    <form className='kycdoc-section' onSubmit={formik.handleSubmit}>
                                        {currentSection === 0 && (
                                            <>
                                                <h5 className='dash-head-1 mb-2'>User Details</h5>
                                                <p className='dash-trans-txt-3 mb-4'>
                                                    Let us know some basic informations about you
                                                </p>
                                                <div className="mb-3">
                                                    <label htmlFor="citizenship" className="form-label">Citizenship</label>
                                                    <Form.Select
                                                        className="custom-select"
                                                        aria-label="Select Citizenship"
                                                        id="citizenship"
                                                        name="KYC_document[0].citizenship"
                                                        value={formik.values.KYC_document[0].citizenship}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    >
                                                        <option value="">Select Option</option>
                                                        {nationalities.map((nat) => (
                                                            <option key={nat.value} value={nat.label}>{nat.label}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {formik.touched.KYC_document?.[0]?.citizenship && formik.errors.KYC_document?.[0]?.citizenship ? (
                                                        <div className="text-danger rq-msg">{formik.errors.KYC_document[0].citizenship}</div>
                                                    ) : null}

                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="country" className="form-label">Country</label>
                                                    <Select
                                                        options={options}
                                                        name="KYC_document[0].country"
                                                        value={selectedOption}
                                                        onChange={option => {
                                                            formik.setFieldValue("KYC_document[0].country", option ? option.value : "");
                                                        }}
                                                        placeholder="Select Country"
                                                        id="country"
                                                    />
                                                    {formik.touched.KYC_document?.[0]?.country && formik.errors.KYC_document?.[0]?.country ? (
                                                        <div className="text-danger rq-msg">{formik.errors.KYC_document[0].country}</div>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="US_resident" className="form-label">Are you liable in US</label>
                                                    <Form.Select
                                                        className="custom-select"
                                                        aria-label="Default select example"
                                                        id="US_resident"
                                                        name="KYC_document[0].US_resident"
                                                        value={formik.values.KYC_document[0].US_resident}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    >
                                                        <option value="">Select Option</option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </Form.Select>
                                                    {formik.touched.KYC_document?.[0]?.US_resident && formik.errors.KYC_document?.[0]?.US_resident ? (
                                                        <div className="text-danger rq-msg">{formik.errors.KYC_document[0].US_resident}</div>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="occupation" className="form-label">Occupation</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="occupation"
                                                        name="KYC_document[0].occupation"
                                                        value={formik.values.KYC_document[0].occupation}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.KYC_document?.[0]?.occupation && formik.errors.KYC_document?.[0]?.occupation ? (
                                                        <div className="text-danger rq-msg">{formik.errors.KYC_document[0].occupation}</div>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="tax_ID" className="form-label">Tax-ID  <span className='option-css'>(Optional)</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="tax_ID"
                                                        placeholder="Gohgdv1452"
                                                        name="KYC_document[0].tax_ID"
                                                        value={formik.values.KYC_document[0].tax_ID}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>

                                                <h5 className='dash-head-2 mb-3'>Birth Information</h5>
                                                <div className="mb-3">
                                                    <label htmlFor="birthday" className="form-label">Birthday</label>
                                                    <input
                                                        type="Date"
                                                        className="form-control"
                                                        id="birthday"
                                                        placeholder="select option"
                                                        name="birth_information[0].birthday"
                                                        value={formik.values.birth_information[0].birthday}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.birth_information?.[0]?.birthday && formik.errors.birth_information?.[0]?.birthday ? (
                                                        <div className="text-danger rq-msg">{formik.errors.birth_information[0].birthday}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="birth_place" className="form-label">Place of Birth</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="birth_place"
                                                        placeholder="select option"
                                                        name="birth_information[0].birth_place"
                                                        value={formik.values.birth_information[0].birth_place}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.birth_information?.[0]?.birth_place && formik.errors.birth_information?.[0]?.birth_place ? (
                                                        <div className="text-danger rq-msg">{formik.errors.birth_information[0].birth_place}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="gender" className="form-label">Gender</label>
                                                    <Form.Select
                                                        className="custom-select"
                                                        aria-label="Default select example"
                                                        id="citizenship"
                                                        name="birth_information[0].gender"
                                                        value={formik.values.birth_information[0].gender}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    >
                                                        <option value="">Select Option</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </Form.Select>
                                                    {formik.touched.birth_information?.[0]?.gender && formik.errors.birth_information?.[0]?.gender ? (
                                                        <div className="text-danger rq-msg">{formik.errors.birth_information[0].gender}</div>
                                                    ) : null}
                                                </div>

                                                {isLoading == true ? (
                                                    <button className="d-send-btn-1 rounded-pill" type="button">
                                                        <Spinner animation="border" />
                                                    </button>
                                                ) : (
                                                    <button className="d-send-btn-1 rounded-pill" type='submit'
                                                        onClick={async () => {
                                                            try {
                                                                const errors = await formik.validateForm();
                                                                console.log('errors', errors)
                                                                if (Object.keys(errors).length === 0) {
                                                                    await formik.handleSubmit();
                                                                    if (currentSection === 0) {
                                                                        handleNext();
                                                                    }
                                                                } else {
                                                                    console.log('Validation errors:', errors);
                                                                }
                                                            } catch (error) {
                                                                console.error('Validation or submission error:', error);
                                                            }
                                                        }}
                                                    >
                                                        Save & Next
                                                    </button>
                                                )}

                                            </>
                                        )}
                                    </form>

                                    <form className='kycdoc-section' onSubmit={stateFormik.handleSubmit}>
                                        {currentSection === 1 && (
                                            <>
                                                <div className='d-flex align-items-end gap-3 mb-3'>
                                                    <div>
                                                        <h5 className='dash-head-2 mb-1'>Disclaimer</h5>
                                                        <div className='dash-trans-txt-2'>
                                                            I've read and agree to all the terms and conditions of REMPIC
                                                        </div>
                                                        {stateFormik.touched.legal_document && stateFormik.errors.legal_document ? (
                                                            <div className="text-danger rq-msg">{stateFormik.errors.legal_document}</div>
                                                        ) : null}
                                                    </div>
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            id="legal_document"
                                                            name="legal_document"
                                                            value={stateFormik.values.legal_document}
                                                            onChange={stateFormik.handleChange}
                                                            onBlur={stateFormik.handleBlur}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='mb-3'>
                                                    <div className='doc-section'>
                                                        <div className='d-flex align-items-center py-3 doc-border'>
                                                            <div className='text-one'>Terms and Conditions</div>
                                                            <div className='ms-auto'>
                                                                <CgFileDocument className='doc-css' />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center py-3 doc-border'>
                                                            <div className='text-one'>Pricelist</div>
                                                            <div className='ms-auto'>
                                                                <CgFileDocument className='doc-css' />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center py-3 doc-border'>
                                                            <div className='text-one'>Terms and condition partner bank</div>
                                                            <div className='ms-auto'>
                                                                <CgFileDocument className='doc-css' />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center py-3 doc-border'>
                                                            <div className='text-one'>Other condition partner Bank</div>
                                                            <div className='ms-auto'>
                                                                <CgFileDocument className='doc-css' />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center py-3'>
                                                            <div className='text-one'>Special condition: cash deposits</div>
                                                            <div className='ms-auto'>
                                                                <CgFileDocument className='doc-css' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='d-flex align-items-end gap-3 mb-3'>
                                                    <div>
                                                        <h5 className='dash-head-2 mb-1'>Privacy Policy</h5>
                                                        <div className='dash-trans-txt-2'>
                                                            I agree to the Privacy Policy of REMPIC
                                                        </div>
                                                        {stateFormik.touched.privacy_policy && stateFormik.errors.privacy_policy ? (
                                                            <div className="text-danger rq-msg">{stateFormik.errors.privacy_policy}</div>
                                                        ) : null}
                                                    </div>
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            id="privacy_policy"
                                                            name="privacy_policy"
                                                            value={stateFormik.values.privacy_policy}
                                                            onChange={stateFormik.handleChange}
                                                            onBlur={stateFormik.handleBlur}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='mb-3'>
                                                    <div className='doc-section'>
                                                        <div className='d-flex align-items-center py-3 doc-border'>
                                                            <div className='text-one'>Data Privacy policy platform</div>
                                                            <div className='ms-auto'>
                                                                <CgFileDocument className='doc-css' />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center py-3 doc-border'>
                                                            <div className='text-one'>Data Protection policy partner bank</div>
                                                            <div className='ms-auto'>
                                                                <CgFileDocument className='doc-css' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className='mb-3 text-center'>
                                                    <img src={envelope} className='en-css' alt='mail' />
                                                    <div className='my-5'>
                                                        <h6 className='fw-semibold'>Open your mail App</h6>
                                                        <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but.</p>
                                                    </div>
                                                </div> */}

                                                <div className='mb-3'>
                                                    <h6 className='lh-base text-center my-4'>Hereby I am opening a bank account in my own name and I confirm the following</h6>
                                                    <ul className='list-unstyled ms-3'>
                                                        <li><p className='dash-trans-txt-2'>1.  I am fully legally responsible for all account activity</p></li>
                                                        <li><p className='dash-trans-txt-2'>2.  I use account exclusively for privacy purposes</p></li>
                                                        <li><p className='dash-trans-txt-2'>3.  I do not act on behalf of, or instructed by a third person</p></li>
                                                    </ul>
                                                </div>

                                                <div className='mb-3 doc-section-two'>
                                                    <p className='dash-trans-txt-2 mb-0'>Beware of trickstes that try to mislead Persons into
                                                        opening bank accounts under false premises (e.g.
                                                        app-testing, job offers credit brokering,identificaton
                                                        for apartment offers) and misuse your account
                                                        for criminal purposes.</p>
                                                </div>

                                                <div className='d-flex align-items-center gap-2 mb-5 justify-content-center'>
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            id="bank_account"
                                                            name="bank_account"
                                                            value={stateFormik.values.bank_account}
                                                            onChange={stateFormik.handleChange}
                                                            onBlur={stateFormik.handleBlur}
                                                        />
                                                    </div>
                                                    <div className='dash-trans-txt-2'>
                                                        <div>I confirm the above listed statements</div>
                                                        {stateFormik.touched.bank_account && stateFormik.errors.bank_account ? (
                                                            <div className="text-danger rq-msg">{stateFormik.errors.bank_account}</div>
                                                        ) : null}
                                                    </div>

                                                </div>

                                                {loading == true ? (
                                                    <button className="d-send-btn-1 rounded-pill" type="button">
                                                        <Spinner animation="border" />
                                                    </button>
                                                ) : (
                                                    <div className='mb-5'>
                                                        <button class="d-send-btn-1 rounded-pill">Next</button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Kycdocuments;
