import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { useFormik } from "formik"
import * as Yup from "yup"
import { makeApiRequest } from "../../config/AxiosService"
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

function Signin() {
    const navigate = useNavigate()
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Enter a valid email").required("Email is required"),
            password: Yup.string("Enter your password").required("Password is required"),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('email', values.email);
            formData.append('password', values.password);
            try {
                setIsLoading(true)
                const params = {
                    url: "user-signin",
                    method: "POST",
                    data: formData
                }
                const loginResp = await makeApiRequest(params)
                console.log('loginResp', loginResp)
                if (loginResp.status == true) {
                    localStorage.setItem("email", values.email)
                    localStorage.setItem("userCredentials", loginResp.token)
                    setIsLoading(false)
                    toast.success(loginResp.message)
                        setTimeout(() => {
                            navigate("/kycdocuments")
                        }, 3000);
                } else {
                    setIsLoading(false)
                    toast.error(loginResp.message)
                }
            } catch (error) {
                setIsLoading(false)
                toast.error("Internal Server Error")
            }
        }
    })

    return (
        <div className='bef-login-home-sec'>
            <section className='bef-signin-h-sec py-5'>
                <div className='container'>
                    <ToastContainer />
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='card dash-card-1'>
                                <div className='card-body'>
                                    <h5 class="dash-head-1 mb-2">Sign in</h5>
                                    <p class="mb-0 dash-trans-txt-2 mb-4">Get an account and control your finances better with full control of your budgets and savings.</p>
                                    <form className='profile-section-one' onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="john@email.com"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text-danger rq-msg">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <div className="input-group">
                                                <input
                                                    type={showOldPassword ? 'text' : 'password'}
                                                    className="form-control password-input"
                                                    id="password"
                                                    placeholder="**********"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    style={{borderRadius:"0.375rem"}}
                                                />
                                                <span className="password-toggle-icon" onClick={toggleOldPasswordVisibility}>
                                                    {showOldPassword ? <FaEye /> : <FaEyeSlash />}
                                                </span>
                                            </div>
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className="text-danger rq-msg">{formik.errors.password}</div>
                                            ) : null}
                                        </div>

                                        <div class="mb-3 d-flex align-items-center gap-2">
                                            {/* <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                            <label class="form-label mb-0" for="exampleCheck1">Remember Me</label> */}
                                            {/* <Link to="/resetpassword" className='link-s-l-1'>Reset Password ?</Link> */}

                                            <Link to="/forgetpassword" className='ms-auto link-s-l-1'>Forgot Password ?</Link>
                                        </div>
                                        <div className="mt-4 mb-3">
                                            {isLoading ? (
                                                <button className="d-send-btn-1 rounded-pill" type="button">
                                                    <Spinner animation="border" />
                                                </button>
                                            ) : (
                                                <button className="d-send-btn-1 rounded-pill" type="submit">Sign In</button>
                                            )}
                                        </div>
                                        <div className="mb-3 d-flex align-items-center gap-2 justify-content-end">
                                            <label className="form-label mb-0">Do not have an Account?</label>
                                            <Link to="/signup" className="link-s-l-1">Sign Up</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default Signin
