import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2';
import tick from "../../assets/images/home/tick.png";
import { Link } from 'react-router-dom';
import videocall from "../../assets/images/home/videocall.png";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useFormik } from "formik"
import * as Yup from "yup"
import { makeApiRequest } from "../../config/AxiosService"
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import OtpInput from 'react-otp-input';


function Accountsection() {

  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('us');
  const [isLoading, setIsLoading] = useState(false)

  const options = countryList().getData().map(country => ({
    value: country.label,
    label: country.label,
  }));

  const placeholders = {
    us: '(201) 555-0123',
    gb: '07400 123456',
    fr: '06 12 34 56 78',
    de: '0151 23456789',
    in: '091234 56789',
  };

  const formik = useFormik({
    initialValues: {
      phone_number: [{
        number: "",
        phone_otp: "",
      }],
      current_Address: [{
        address_1: "",
        address_2: "",
        city: "",
        resident_country: "",
        zip_code: "",
      }]
    },
    validationSchema: Yup.object({
      phone_number: Yup.array().of(
        Yup.object({
          number: Yup.string().required("Phone Number is Required"),
          phone_otp: Yup.string().required("Enter Your SMS OTP"),
        })
      ),
      current_Address: Yup.array().of(
        Yup.object({
          address_1: Yup.string().optional(),
          address_2: Yup.string().required("Enter Your House Number"),
          city: Yup.string().required("Enter Your City"),
          resident_country: Yup.string().required("Select Your Resident Country"),
          zip_code: Yup.string().required("Enter Your ZIP Code"),
        })
      )
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("phone_number[0].number", values.phone);
      formData.append("phone_number[0].phone_otp", values.phone_number[0].phone_otp);
      formData.append("current_Address[0].address_1", values.current_Address[0].address_1 || "");
      formData.append("current_Address[0].address_2", values.current_Address[0].address_2);
      formData.append("current_Address[0].city", values.current_Address[0].city);
      formData.append("current_Address[0].resident_country", values.current_Address[0].resident_country);
      formData.append("current_Address[0].zip_code", values.current_Address[0].zip_code);

      try {
        setIsLoading(true);
        const params = {
          url: "user-kyc-verification",
          method: "POST",
          data: formData
        };
        const kycResp = await makeApiRequest(params);
        console.log("kycResp----", kycResp);
        if (kycResp.status == true) {
          toast.success("Account Data Created")
          setIsLoading(false);
        }
      } catch (error) {
        toast.error("Internal Server Error");
        setIsLoading(false);
      }
    }
  });

  const selectedOption = options.find(option => option.value === formik.values.current_Address[0].resident_country) || null;

  return (
    <div className='accountsection'>
      <div className='bef-login-home-sec py-5'>
        <div className='container'>
          <ToastContainer />
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='card dash-card-1'>
                <div className='card-body'>
                  <h5 className='dash-head-1 mb-5 text-center'>Account</h5>
                  <h5 className='dash-head-1 mb-3'>What is your mobile Number</h5>
                  <div className='profile-section-one'>
                    <div className="mb-4">
                      <label htmlFor="phone" className="form-label">Phone Number</label>
                      <div className='d-flex align-items-lg-center gap-3 flex-column flex-lg-row'>
                        <PhoneInput
                          country={country}
                          value={phone}
                          onChange={(value, countryData) => {
                            setPhone(value);
                            setCountry(countryData.countryCode);
                          }}
                          placeholder={placeholders[country] || 'Enter phone number'}
                          inputProps={{
                            name: 'phone',
                            id: 'phone',
                            className: 'form-control',
                          }}
                        />
                        <div>
                          <button type='button' className='btn opt-btn' data-bs-toggle="modal" data-bs-target="#getopt">
                            Get OTP
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <div className='d-flex align-items-center gap-4'>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="" name='phonenumber' checked />
                        </div>
                        <div>
                          <h6 className='semibold border-bottom border-light-subtle pb-2'>+{phone}</h6>
                          <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>We will send you an Ident code to this mobile number via SMS during the identification.</p>
                        </div>
                      </div>
                    </div>
                    <h5 className='dash-head-1 mb-3'>What is your Current Address</h5>
                    <div className="mb-3">
                      <label htmlFor="apartment" className="form-label">Address line 1</label>
                      <input type="text" className="form-control" placeholder="Apt 203" />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="number" className="form-label">Address line 2</label>
                      <input type="text" className="form-control" placeholder="1245715251" />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">City</label>
                      <input type="text" className="form-control" placeholder="1245715251" />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">Resident Country</label>
                      <Select
                        options={options}
                        name="current_Address[0].resident_country"
                        value={selectedOption}
                        onChange={option => {
                          formik.setFieldValue("current_Address[0].resident_country", option ? option.value : "");
                        }}
                        placeholder="Select Country"
                        id="country"
                      />
                      {formik.touched.current_Address?.[0]?.resident_country && formik.errors.current_Address?.[0]?.resident_country ? (
                        <div className="text-danger rq-msg">{formik.errors.current_Address[0].resident_country}</div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="zipcode" className="form-label">Zip Code</label>
                      <input type="text" className="form-control" placeholder="C1E 9Z9" />
                    </div>

                    <div className='mb-3'>
                      <div className='d-flex align-items-center gap-4'>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="" name='document' checked />
                        </div>
                        <div>
                          <h6 className='semibold pb-2'>Your readily available identity document</h6>
                          <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>Your identity document will be checked during
                            the identification, so please have it ready and
                            at hand.</p>
                        </div>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <div className='d-flex align-items-center gap-4'>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="" name='consent' checked />
                        </div>
                        <div>
                          <h6 className='semibold pb-2'>Consent</h6>
                          <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>I agree to the Dnow terms of Service and
                            have read the Privacy Policy. I give my explicit
                            consent that.</p>
                        </div>
                      </div>
                    </div>

                    <p className='dash-trans-txt-2 pb-2'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    <div className='mb-5'>
                      <button type="button" class="d-send-btn-1 rounded-pill" data-bs-toggle="modal" data-bs-target="#videocall">
                        Proceed
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* <!-- Opt Modal --> */}
        <div class="modal fade" id="getopt" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="getoptLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">
                <h1 class="modal-title fs-5" id="getoptLabel"></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className='card dash-card-1'>
                  <div className='card-body py-5'>

                    <h5 className="dash-head-1  text-center">Enter OTP</h5>
                    <p className='dash-trans-txt-2 text-center mb-4'>Check your Messages</p>
                    <div className='px-lg-4 profile-section-one '>
                      <OtpInput
                        value=""
                        onChange="hyugutg"
                        numInputs={6}
                        isInputNum={true}
                        renderSeparator={<span style={{ margin: '0 10px' }}> -</span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className={`form-control ${props.className}`}
                            style={{
                              textAlign: 'center',
                              flexGrow: 1,
                              width: 'auto',
                              minWidth: '50px',
                              height: '50px'
                            }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            // Prevent non-numeric input
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        )}
                        containerStyle={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      />
                      <div className='text-end'>
                        <button className='dash-trans-txt-2 mt-1 bg-transparent border-0' >
                          Resend OTP
                        </button>

                      </div>
                      <button className='d-send-btn-gr-1 mt-4'>
                        <div className='d-send-btn-gr-1-1'>
                          Submit
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0">
                {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Video-call Modal --> */}
        <div class="modal fade" id="videocall" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="videocallLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">
                <h1 class="modal-title fs-5" id="videocallLabel"></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className='mb-3'>
                  <h5 className='dash-head-1 mb-3 text-center'>Time to verify your identity with a short video call</h5>
                  <div className='d-flex align-items-center gap-4 justify-content-center p-2 mx-auto video-sec'>
                    <div><img src={videocall} alt='correct' className='video-img' /></div>
                    <div className='dash-trans-txt-2'>You wonder how the video-call works? We’ll show you</div>
                  </div>
                </div>
                <Link to='/useraccountform' className='d-flex justify-content-center'><button class="d-send-btn-2 rounded-pill">Next</button></Link>
              </div>
              <div class="modal-footer border-0">
                {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accountsection