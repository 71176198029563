import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { makeApiRequest } from "../../config/AxiosService"
import { toast, ToastContainer } from 'react-toastify'

function Resetpassword() {

    const { token } = useParams();
    const Navigate = useNavigate()
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    const togglePasswordVisibility = () => {
        setNewPassword(!newPassword);
    };

    const toggleConfirmPassword = () => {
        setConfirmPassword(!confirmPassword)
    }
    const formik = useFormik({
        initialValues: { password: "", confirmPassword: "" },
        validationSchema: Yup.object({
            password: Yup.string("Enter your password")
                .required("Password is required")
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`\{|}~\\])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`\{|}~\\]{8,}$/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Passwords does not Match").required("Confirm Password is required")
        }),

        onSubmit: async (values) => {

            const datas = new FormData()
            datas.append("password", values.password)
            datas.append("confirmPassword", values.confirmPassword)
            datas.append("token", token)
            try {
                let params = {
                    url: "user-reset-password",
                    method: "POST",
                    data: datas
                }
                const response = await makeApiRequest(params)

                if (response.status == true) {
                    toast.success(response.message)
                    setTimeout(() => {
                        Navigate("/signin")
                    }, 2000);
                } else {
                    toast.success(response.message)
                    localStorage.clear();

                }
            } catch (error) {
                toast.error("Something Went Wrong...")
            }
        }
    })

    return (
        <div className='resetpassword'>
             <ToastContainer />
            <div className='bef-login-home-sec'>
                <section className='bef-signin-h-sec py-5'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <div className='card dash-card-1'>
                                    <div className='card-body'>
                                        <h5 className="dash-head-1 mb-2">Reset Password</h5>
                                        <p className="mb-0 dash-trans-txt-2 mb-4">
                                            Get an account and control your finances better with full control of your budgets and savings.
                                        </p>

                                        <div className='profile-section-one'>
                                            <form onSubmit={formik.handleSubmit}>
                                                <div className="mb-3 position-relative">
                                                    <label htmlFor="password" className="form-label">New Password</label>
                                                    <div className='input-group'>
                                                        <input
                                                            type={newPassword ? 'text' : 'password'}
                                                            className="form-control password-input"
                                                            name="password"
                                                            id="password"
                                                            value={formik.values.password}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            placeholder="**********"
                                                        />
                                                        <span
                                                            className="password-toggle-icon"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            {newPassword ? <FaEye /> : <FaEyeSlash />}
                                                        </span>
                                                    </div>
                                                    {formik.touched.password && formik.errors.password ? (
                                                        <div className="text-danger rq-msg">{formik.errors.password}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3 position-relative">
                                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                                    <div className='input-group'>
                                                        <input
                                                            type={confirmPassword ? 'text' : 'password'}
                                                            className="form-control password-input"
                                                            name="confirmPassword"
                                                            id="confirmPassword"
                                                            value={formik.values.confirmPassword}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            placeholder="**********"
                                                        />
                                                        <span
                                                            className="password-toggle-icon"
                                                            onClick={toggleConfirmPassword}
                                                        >
                                                            {confirmPassword ? <FaEye /> : <FaEyeSlash />}
                                                        </span>
                                                    </div>
                                                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                        <div className="text-danger rq-msg">{formik.errors.confirmPassword}</div>
                                                    ) : null}
                                                </div>
                                                {/* <div className="mb-3 d-flex align-items-center gap-2">
                                                    <Link to="/forgetpassword" className='ms-auto link-s-l-1'>Forgot Password?</Link>
                                                </div> */}

                                                <div className="mt-4 mb-3">
                                                    <button type='submit' className="d-send-btn-1 rounded-pill">Reset Password</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Resetpassword;
